.left-sidebar {
  background-color: #ffffff;
  /* padding: 20px; */
  border-right: 1px solid #dee2e6;
  width: 100%;
  overflow-y: auto !important;
  height: 65vh;
}

.right-sidebar {
  padding: 0px !important;
  
}

.chat-messages {
  height: calc(75vh - 150px); /* Adjust the height of chat messages section as needed */
  overflow-y: auto;
}

.chat-message {
  margin-bottom: 10px;
}

.chat-input {
  margin-top: 20px;
  display: flex;
}

.left-sidebar::-webkit-scrollbar {
  width: 10px; 
  height: 30% !important;
}

.left-sidebar::-webkit-scrollbar-track {
  background: lightgray; 
  max-height: 30% !important; height: 30% !important;
}

.left-sidebar::-webkit-scrollbar-thumb {
  background-color: #01A8CC; 
  border-radius: 5px; 
  height: 30% !important;
}


